<template>
  <div>
    <b-row class="header_row">
      <b-col>
        <h1>
          {{ $t("Report") }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          {{ repr }}, {{ $t(short_type) }} {{ short_number }}
          <b-link variant="outline-primary" @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <a class="text-info" :href="build_url(true, true)">
            <b-icon-download />
          </a>
        </h2>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <div v-if="!is_empty">
        <b-row class="reports">
          <div v-for="{ obj, data } in compl_report" :key="obj.pk" cols="2">
            <b-table-simple>
              <b-tr>
                <th class="blue-bg" colspan="3">
                  {{ $t(short_type) }} {{ short_number }}
                </th>
              </b-tr>
              <b-tr>
                <th class="gray-bg" colspan="3">{{ obj.name }}</th>
              </b-tr>
              <b-tr>
                <b-th class="orange-bg">{{ $t("HC") }}</b-th>
                <b-th class="lightblue-bg">{{ $t("Avg") }}</b-th>
                <b-th class="green-bg">{{ $t("Count") }}</b-th>
              </b-tr>
              <b-tr v-for="r in data" :key="r.category">
                <b-td>{{ r.obj }}</b-td>
                <b-td
                  ><span v-if="r.avg_pscore">{{ r.avg_pscore }}%</span></b-td
                >
                <b-td>{{ r.count }}</b-td>
              </b-tr>
            </b-table-simple>
          </div>
        </b-row>
      </div>
      <b-row v-else class="header_row">
        <b-col>
          <h2>
            {{ $t("Nothing found") }}
          </h2>
        </b-col>
      </b-row>

      <b-modal v-model="show_modal" hide-footer>
        <report-form @ok="go_to" />
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import { merge, uniq, orderBy } from "lodash-es";
import { getISOWeek } from "date-fns";
import { capitalyze, dict_to_search, to_query } from "../shared";
import ReportForm from "../components/ReportForm";
import { SERVER } from "../constants";

export default {
  name: "BaseReport",
  components: { ReportForm },
  mounted() {
    this.load();
  },
  data() {
    return {
      loading: false,
      report: [],
      report_full: [],
      errors: {},
      report_grouped_report: [],
      report_full_grouped_report: [],
      show_modal: false,

      short_number: 1,
      short_type: "week",
    };
  },
  computed: {
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), to: { name: "Reports" } },
        { text: this.$t("Health Check Report"), active: true },
      ];
    },
    repr() {
      let ret = "";
      ret += this.text_repr;
      return ret;
    },
    is_empty() {
      return !this.report.length;
    },
    cat_list() {
      //const cat = uniq(this.month_report.map(m => m.category));
      //return cat
      return orderBy(
        this.$store.getters.category.filter((c) => !c.deleted),
        "pk"
      ).map((c) => c.pk);
    },
    month_repr() {
      const start = new Date(this.$route.query.start);
      return `${this.$t("Month")} ${start.getMonth() + 1}`;
    },
    week_repr() {
      const start = new Date(this.$route.query.start);
      const text = this.$t("Week");
      return `${text} ${getISOWeek(start)}`;
    },
    text_repr() {
      let ret = "Factory";
      const dep = this.$route.query.departament || null;
      if (dep) {
        ret = this.$store.getters.departament_as_pk[dep]?.name || `Dep ${dep}`;
      }
      return ret;
    },
    current_type() {
      let dep = "factory";
      if (this.$route.query.departament) {
        dep = "departament";
      }
      return dep;
    },
    current_group_el() {
      let ret = "departament";
      if (this.current_type == "departament") {
        ret = "cell";
      }
      return ret;
    },

    cat_as_dict() {
      return this.$store.getters.category.reduce(
        (r, c) => merge(r, { [c.pk]: c._name }),
        {}
      );
    },
    cells_list() {
      const cells = this.grouped_data;
      const cells_ids = uniq(this.report.map((r) => r[this.current_group_el]));
      return cells_ids.map((r) => cells.find((o) => r == o.pk && !o.deleted));
    },
    group_objs() {
      const gr = this.current_group_el;
      let ret;
      if (gr === "cell") {
        const departament = this.$store.getters.departament.find(
          (d) => d.pk == this.$route.query.departament
        );
        ret = this.$store.getters.cell.filter(
          (c) => c.departament_uuid == departament.uuid && !c.deleted
        );
      } else {
        ret = this.$store.getters.departament.filter((d) => !d.deleted);
      }
      return orderBy(ret, "pk");
    },
    compl_report() {
      let ret = [];
      const categories = this.cat_list;
      const group_objs = this.group_objs;
      for (const gr_obj of group_objs) {
        //
        const gr_id = gr_obj.pk;
        let line_data = [];
        let data = {
          obj: gr_obj,
          data: line_data,
        };
        for (const cat of categories) {
          const category = this.cat_as_dict[cat];
          const data =
            this.report.find(
              (r) => r.category == cat && r[this.current_group_el] == gr_id
            ) || {};

          line_data.push({
            obj: category,
            ...data,
          });
        }
        line_data = orderBy(line_data, "category");
        ret.push(data);
      } // добавим total
      let total_line = [];
      let total_obj = {
        obj: { pk: -2, name: "Total" },
        data: total_line,
      };
      if (!this.is_empty) {
        for (const cat of categories) {
          const category = this.cat_as_dict[cat];
          const data = this.report_full.find((r) => r.category == cat) || {};

          total_line.push({
            obj: category,
            ...data,
          });
        }
        ret.push(total_obj);
      }
      return ret;
    },
    grouped_data() {
      return this.$store.getters[this.current_group_el];
    },
  },
  methods: {
    load() {
      this.loading = true;
      this.$store
        .dispatch("load_data", [
          "additional_info",
          "category",
          "departament",
          "factory",
        ])
        .then(() =>
          this.load_data()
            .then((data) => {
              this.report = data["data"];
              this.report_full = data["data_total"];

              this.short_number = data["number"];
              this.short_type = capitalyze(data["period"]);
            })
            .then(
              () => (this.loading = false),
              () => (this.loading = false)
            )
        );
    },
    go_to(args) {
      this.show_modal = false;
      this.$router.push({ name: this.$route.name, query: to_query(args) });
      this.load();
    },
    build_url(xls = false, with_server = false) {
      const period = this.$route.query["period"] || "week";
      const full = false;
      const start = this.$route.query.start;
      let set_data = merge({}, this.$route.query, {
        full,
        period,
        start,
      });
      if (xls === true) {
        set_data["frmt"] = "xlsx";
      }
      const query_str = dict_to_search(set_data);
      let url = `api/reports/report/?${query_str}`;
      if (with_server) {
        url = `${SERVER}/${url}`;
      }
      return url;
    },
    load_data() {
      const url = this.build_url(false, false);

      return this.$store
        .dispatch("get", {
          url: url,
          raw: true,
        })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          return Promise.reject(
            resp.json().then((err) => {
              this.errors = merge({}, this.errors, err);
              return [];
            })
          );
        })
        .then((r) => r);
    },
    get_name(id) {
      let obj = this.grouped_data.find((r) => r.pk == id);
      return obj ? obj._name : id;
    },
  },
};
</script>

<style lang="scss">
.full_report {
  overflow: auto;
  margin: 0;

  .center {
    text-align: center;
    vertical-align: middle;
  }
}

.reports {
  gap: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 1vw;
  max-width: 98vw;
  overflow: auto;

  // [class^="col-"] {
  //   display: flex;
  //   flex-direction: row;
  //   overflow: auto;
  //   max-width: 48%;
  //   padding: 0;
  //   .sub-table {
  //     max-width: min-content;
  //   }
  // }
}

.row.col {
  flex-wrap: nowrap;
  overflow: auto;

  .sub-table {
    max-width: 250px;
  }
}
</style>
